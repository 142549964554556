import React from 'react';
import { Link, graphql } from 'gatsby';
import { view } from 'react-easy-state';
import _ from 'lodash';
import Masonry from 'react-masonry-component';

import Layout from '../components/layout';
import Repo from '../components/repo';
import main from '../stores';

const Card = ({ owner, repos }) => (
  <div
    css={[
      tw`w-full m-2 p-4 pb-0 rounded-lg border-2 border-grey-light bg-white overflow-hidden`,
      {
        a: {
          textDecoration: 'none'
        }
      }
    ]}
  >
    <div
      css={[
        tw`flex flex-col items-center justify-center bg-grey-lighter p-4 text-center`,
        {
          margin: '-1rem',
          marginBottom: 0
        }
      ]}
    >
      <a
        href={`https://github.com/${owner.login}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        css={tw`font-light text-2xl flex flex-col items-center`}
      >
        {owner && (
          <img
            css={tw`block h-16 rounded-full mb-2`}
            src={`${owner.avatar_url}&s=64`}
            alt={owner.login}
          />
        )}
        <span css={tw`block text-base font-bold text-brand-dark`}>{owner.login}</span>
        {owner.name}
      </a>
      {repos && (
        <span css={tw`mt-1 text-xs font-bold opacity-50`}>
          {repos.length} list{repos.length > 1 && 's'}
        </span>
      )}
    </div>

    {repos && (
      <ul css={tw`list-reset flex flex-wrap w-full`}>
        {repos.map(repo => (
          <Repo key={repo.slug} {...repo} showChild={false} showParent />
        ))}
      </ul>
    )}
  </div>
);

class CuratorsPage extends React.Component {
  render() {
    const { allOwnersJson, allAweRepo } = this.props.data;
    const owners = allOwnersJson.edges.map(edge => edge.node);
    const allRepos = allAweRepo.edges.map(edge => edge.node);

    const sortedOwners = _.orderBy(_.map(owners, (owner) => {
      const repos = _.filter(allRepos, repo => repo.owner === owner.login);
      return {
        ...owner,
        repos,
        totalRepos: repos.length
      };
    }), ['totalRepos', 'login'], ['desc', 'asc']);

    return (
      <Layout title="Curators">
        <div>
          <Masonry
            ref={c => { main.masonryCurators = main.masonryCurators || c.masonry; }}
            elementType="ul"
            css={[tw`list-reset`, { margin: '0 -0.5rem', zIndex: 10 }]}
          >
            {sortedOwners.map((owner) => (
              <li key={owner.login} css={tw`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex flex-wrap items-center`}>
                <Card owner={owner} repos={owner.repos} />
              </li>
            ))}
          </Masonry>
        </div>
      </Layout>
    );
  }
}

export default view(CuratorsPage);

export const query = graphql`
  query CuratorsQuery {
    allOwnersJson {
      edges {
        node {
          login
          name
          avatar_url
        }
      }
    }
    allAweRepo {
      edges {
        node {
          owner
          title
          slug
          summary
          parentRepo {
            slug
            title
          }
          updatedAt
          pushedAt
          stars
          watchers
          notFound
          parentRepo {
            title
          }
        }
      }
    }
  }
`;
